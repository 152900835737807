import ReactGA from 'react-ga4';

export const initGA = (measurementId: string): void => {
  ReactGA.initialize(measurementId);
};

export const logPageView = (path: string): void => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

interface LogEventProps {
  category: string;
  action: string;
  label?: string;
}

export const logEvent = ({ category, action, label }: LogEventProps): void => {
  ReactGA.event({ category, action, label });
};
