import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SettingsPage.css';
import { ArrowLeft, Bell, Lock, User, CreditCard, HelpCircle, Moon } from 'lucide-react';

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [notifications, setNotifications] = useState(true);

  return (
    <div className={`settings-page ${darkMode ? 'dark-mode' : ''}`}>
      <header className="settings-header">
        <button className="back-button" onClick={() => navigate('/home')}>
          <ArrowLeft size={24} />
        </button>
      </header>
      <main className="settings-main">
        <section className="settings-section">
          <h2>Account</h2>
          <div className="setting-item">
            <User size={20} />
            <span>Profile Information</span>
          </div>
          <div className="setting-item">
            <Lock size={20} />
            <span>Password &amp; Security</span>
          </div>
          <div className="setting-item">
            <CreditCard size={20} />
            <span>Billing &amp; Subscriptions</span>
          </div>
        </section>
        <section className="settings-section">
          <h2>Preferences</h2>
          <div className="setting-item">
            <Moon size={20} />
            <span>Dark Mode</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="setting-item">
            <Bell size={20} />
            <span>Notifications</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={notifications}
                onChange={() => setNotifications(!notifications)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </section>
        <section className="settings-section">
          <h2>Support</h2>
          <div className="setting-item">
            <HelpCircle size={20} />
            <span>Help Center</span>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SettingsPage;