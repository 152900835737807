import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Workflow from './Workflow';
import LoginPage from './LoginPage';
import FlowDetailsPage from './FlowDetailsPage';
import SettingsPage from './SettingsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/workflow" element={<Workflow />} />
        <Route path="/flow/:flowId" element={<FlowDetailsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </Router>
  );
}

export default App;