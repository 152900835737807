import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './FlowDetailsPage.css';
import { ArrowLeft } from 'lucide-react';

interface FlowDetails {
  id: string;
  name: string;
  imageUrl: string;
  tags: string[];
  description: string;
  rating: number;
  author: string;
  runs: number;
  likes: number;
  inputType: string;
  outputType: string;
  processingTime: string;
  lastUpdated: string;
}

interface RelatedFlow {
  id: string;
  name: string;
  imageUrl: string;
  rating: number;
}

interface Rating {
  author: string;
  value: number;
  comment: string;
}

const FlowDetailsPage: React.FC = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const navigate = useNavigate();
  const [flowDetails, setFlowDetails] = useState<FlowDetails | null>(null);
  const [relatedFlows, setRelatedFlows] = useState<RelatedFlow[]>([]);
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  useEffect(() => {
    // Mock data for the main flow
    const mockFlowDetails: FlowDetails = {
      id: flowId || '',
      name: `Flow ${flowId}`,
      imageUrl: `https://placehold.co/400x300/2196f3/ffffff?text=Flow${flowId}`,
      tags: ['NLP', 'Transformer', 'Text'],
      description: 'This is a detailed description of the flow. It explains what the flow does and how it can be used. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      rating: 4.5,
      author: 'Thomas',
      runs: 1234,
      likes: 567,
      inputType: "Text",
    outputType: "Processed Text",
    processingTime: "~2 seconds",
    lastUpdated: "2024-03-15"
    };

    // Mock data for related flows
    const mockRelatedFlows: RelatedFlow[] = [
      {
        id: '2',
        name: 'Text Summarization Flow',
        imageUrl: 'https://placehold.co/300x200/4caf50/ffffff?text=Summary',
        rating: 4.2,
      },
      {
        id: '3',
        name: 'Sentiment Analysis Flow',
        imageUrl: 'https://placehold.co/300x200/ff9800/ffffff?text=Sentiment',
        rating: 4.7,
      },
      {
        id: '4',
        name: 'Language Translation Flow',
        imageUrl: 'https://placehold.co/300x200/9c27b0/ffffff?text=Translation',
        rating: 4.1,
      },
      {
        id: '5',
        name: 'Named Entity Recognition Flow',
        imageUrl: 'https://placehold.co/300x200/e91e63/ffffff?text=NER',
        rating: 4.4,
      },
    ];

    // Mock data for ratings
    const mockRatings: Rating[] = [
      { author: 'Alice', value: 5, comment: 'Amazing flow! Helped a lot with NLP tasks.' },
      { author: 'Bob', value: 4, comment: 'Great, but could use more documentation.' },
      { author: 'Charlie', value: 3.5, comment: 'Good flow, but had some issues with performance.' },
    ];

    // Calculate average rating
    const avgRating = mockRatings.reduce((acc, rating) => acc + rating.value, 0) / mockRatings.length;

    setFlowDetails(mockFlowDetails);
    setRelatedFlows(mockRelatedFlows);
    setRatings(mockRatings);
    setAverageRating(avgRating);
  }, [flowId]);

  const handleRunFlow = () => {
    console.log(`Running flow ${flowId}`);
  };

  const handleViewFlow = () => {
    console.log(`Viewing flow ${flowId}`);
  };

  const handleRelatedFlowClick = (relatedFlowId: string) => {
    navigate(`/flow/${relatedFlowId}`);
  };

  const handleLikeClick = () => {
    if (flowDetails) {
      setFlowDetails({
        ...flowDetails,
        likes: isLiked ? flowDetails.likes - 1 : flowDetails.likes + 1
      });
      setIsLiked(!isLiked);
    }
  };

  if (!flowDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flow-details-page">
      <header>
      <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft size={24} />
        </button>
      </header>
      <div className="flow-content">
      <div className="flow-image-container">
        <div className="flow-image">
            <img src={flowDetails.imageUrl} alt={flowDetails.name} />
        </div>
        <h2 className="flow-title">{flowDetails.name}</h2>
        <p className="flow-author">Made by {flowDetails.author}</p>
        <div className="flow-stats">
            <span className="runs-count">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="16" height="16">
                <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
            </svg>
            {flowDetails.runs} runs
            </span>
            <button 
            className={`like-button ${isLiked ? 'liked' : ''}`} 
            onClick={handleLikeClick}
            >
            <span className="heart-icon"></span>
            {flowDetails.likes} likes
            </button>
        </div>
        <div className="flow-buttons">
            <button className="flow-button" onClick={handleRunFlow}>Run This Flow</button>
            <button className="flow-button" onClick={handleViewFlow}>View This Flow</button>
        </div>
        </div>
        <div className="flow-info">
          <div className="tags">
            {flowDetails.tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
          </div>
          <p className="description">{flowDetails.description}</p>
            <div className="critical-details">
                <h3>Critical Details</h3>
                <ul>
                <li><strong>Input Type:</strong> {flowDetails.inputType}</li>
                <li><strong>Output Type:</strong> {flowDetails.outputType}</li>
                <li><strong>Avg. Processing Time:</strong> {flowDetails.processingTime}</li>
                <li><strong>Last Updated:</strong> {flowDetails.lastUpdated}</li>
                </ul>
            </div>
        </div>
      </div>
      <div className="ratings-section">
        <div className="ratings-header">
          <h2 className="ratings-title">User Ratings</h2>
          <div className="average-rating">{averageRating.toFixed(1)} / 5</div>
        </div>
        <div className="ratings-list">
          {ratings.map((rating, index) => (
            <div key={index} className="rating-item">
              <div className="rating-header">
                <span className="rating-author">{rating.author}</span>
                <span className="rating-value">{rating.value} / 5</span>
              </div>
              <p className="rating-comment">{rating.comment}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="related-flows">
        <h2>Related Flows</h2>
        <div className="flow-grid">
          {relatedFlows.map((flow) => (
            <div key={flow.id} className="flow-card" onClick={() => handleRelatedFlowClick(flow.id)}>
              <img src={flow.imageUrl} alt={flow.name} className="flow-card-image" />
              <div className="flow-card-content">
                <h3 className="flow-card-title">{flow.name}</h3>
                <div className="flow-card-rating">{flow.rating.toFixed(1)} / 5</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlowDetailsPage;