import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import './Workflow.css';
import { callClaudeAPI, ClaudeModel } from './api/api';
import { Link, Pencil, Trash2 } from 'lucide-react';
import ReactGA from 'react-ga4';

interface Agent {
    id: string;
    name: string;
    x: number;
    y: number;
    prompt: string;
    image: string;
    model: ClaudeModel;
  }

  interface Connection {
    from: string;
    to: string;
    bidirectional: boolean;
  }

const Workflow: React.FC = () => {

    const [agentTemplates] = useState<Agent[]>([
        { id: 'claude-3-5-sonnet', name: 'Claude 3.5 Sonnet', x: 0, y: 0, prompt: '', image: 'https://10web.io/wp-content/uploads/2024/07/Claude.png', model: 'claude-3-5-sonnet-20240620' },
        { id: 'claude-3-opus', name: 'Claude 3 Opus', x: 0, y: 0, prompt: '', image: 'https://10web.io/wp-content/uploads/2024/07/Claude.png', model: 'claude-3-opus-20240229' },
        { id: 'claude-3-haiku', name: 'Claude 3 Haiku', x: 0, y: 0, prompt: '', image: 'https://10web.io/wp-content/uploads/2024/07/Claude.png', model: 'claude-3-haiku-20240307' },
      ]);
    
  const [workflow, setWorkflow] = useState<Agent[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [draggedAgent, setDraggedAgent] = useState<Agent | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectingFrom, setConnectingFrom] = useState<string | null>(null);
  const [tempConnection, setTempConnection] = useState<{ x1: number, y1: number, x2: number, y2: number } | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [activeTab, setActiveTab] = useState<'marketplace' | 'chat' | 'terminal' | 'guide'>('marketplace');
  const [terminalOutput, setTerminalOutput] = useState<string>('Terminal output will appear here...');
  const [iterations, setIterations] = useState<string>('1');
  const [displayedOutput, setDisplayedOutput] = useState<string>('');
  const svgRef = useRef<SVGSVGElement>(null);
  const [agentMessages, setAgentMessages] = useState<{ agent: string, content: string }[]>([]);
  const [nextAgentId, setNextAgentId] = useState(1);
  const [editingAgentId, setEditingAgentId] = useState<string | null>(null);
  const [estimatedTokens, setEstimatedTokens] = useState<number>(0);
  const [agentName, setAgentName] = useState('');

  type TaggedMessage = {
    agentId: string;
    agentName: string;
    content: string;
  };

  ReactGA.initialize('G-JXQ1XWMPRZ');

  const workflowRef = useRef<HTMLDivElement>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const getTransformedPoint = (x: number, y: number) => {
    const { scale, positionX, positionY } = transformComponentRef.current?.state || { scale: 1, positionX: 0, positionY: 0 };
    return {
      x: (x - positionX) / scale,
      y: (y - positionY) / scale
    };
  };

  const handleAgentCommunication = (messages: { agent: string, content: string }[]) => {
    setAgentMessages(messages);
    setActiveTab('chat');
};

function calculateIntersection(x1: number, y1: number, x2: number, y2: number, boxWidth: number, boxHeight: number) {
    const centerX1 = x1 + boxWidth / 2;
    const centerY1 = y1 + boxHeight / 2;
    const centerX2 = x2 + boxWidth / 2;
    const centerY2 = y2 + boxHeight / 2;
  
    const dx = centerX2 - centerX1;
    const dy = centerY2 - centerY1;
    const angle = Math.atan2(dy, dx);
  
    let intersectX, intersectY;
  
    if (Math.abs(Math.cos(angle)) > Math.abs(Math.sin(angle))) {
      intersectX = dx > 0 ? x2 : x2 + boxWidth;
      intersectY = centerY2;
    } else {
      intersectX = centerX2;
      intersectY = dy > 0 ? y2 : y2 + boxHeight;
    }
  
    const offsetDistance = 5;
    intersectX -= Math.cos(angle) * offsetDistance;
    intersectY -= Math.sin(angle) * offsetDistance;
  
    return { x: intersectX, y: intersectY };
  }

  const createNewAgent = (template: Agent): Agent => {
    const newId = `${template.id}_${nextAgentId}`;
    setNextAgentId(prevId => prevId + 1);
    return { ...template, id: newId, name: `${template.name}` };
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/workflow" });
  }, []);

  useEffect(() => {
    updateTokenEstimate();
  }, [workflow, connections, iterations]);

  const updateTokenEstimate = () => {
    const tokens = estimateWorkflowTokens(workflow, connections, parseInt(iterations) || 0);
    setEstimatedTokens(tokens);
  };

  const renderGuideContent = () => (
    <div className="guide-content">
      <h2>How to Use the Flow Creator</h2>
      <p>Hey there! In this section, we provide a guide on how to get started with our flow creator. For more detailed information though, please visit our <a href="https://docs.zarathustra.network" target="_blank" rel="noopener noreferrer">documentation</a> and find an <a href="https://docs.zarathustra.network/flows/creating-your-first-flow" target="_blank" rel="noopener noreferrer">example workflow</a> here.</p>
      <h3>Getting Started</h3>
      <ol>
        <li>Drag and drop agents from the Library into the Workflow Area.</li>
        <li>Give an agent a name by double clicking on them, typing, and hitting enter.</li>
        <li>Connect agents by first hovering over one agent and clicking their chain button, then click on another agent to establish the connection.</li>
        <li>Edit an agent's prompt by clicking the pencil button, then entering a prompt in the prompt section under the Workflow Area.</li>
        <li>Delete an agent by hovering over them and clicking the trash button.</li>
        <li>Set the number of iterations for your workflow, which determines how many times the conversation will go back and forth between agents.</li>
        <li>Click 'Run Workflow' to execute your designed workflow.</li>
        <li>View your workflow results in the chat area, and use the terminal to diagnose potential issues.</li>
      </ol>
      <h3>Tips:</h3>
      <ul>
        <li>Try not to use copyrighted figures or inflammatory language in your prompts, some models reject this usage.</li>
        <li>Agents process information in the order of their connections.</li>
        <li>You can't connect an agent to itself or create duplicate connections.</li>
        <li>The estimated credit cost updates as you build your workflow.</li>
      </ul>
      <p>Any other questions? Feel free to reach out at team@zarathustra.network</p>
    </div>
  );

  const getMousePositionInSVG = (event: React.MouseEvent): { x: number; y: number } => {
    const svg = svgRef.current;
    if (!svg) return { x: 0, y: 0 };
  
    const CTM = svg.getScreenCTM();
    if (!CTM) return { x: 0, y: 0 };
  
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
  
    const transformedPoint = point.matrixTransform(CTM.inverse());
    return { x: transformedPoint.x, y: transformedPoint.y };
  };

  const handleAgentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentName(e.target.value);
    if (selectedAgent) {
      handleAgentRename(selectedAgent.id, e.target.value);
    }
  };

  const getReverseTransformedPoint = (x: number, y: number) => {
    const { scale, positionX, positionY } = transformComponentRef.current?.state || { scale: 1, positionX: 0, positionY: 0 };
    return {
      x: x * scale + positionX,
      y: y * scale + positionY
    };
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, agent: Agent) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(agent));
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const typeEffect = (text: string, speed: number = 10) => {
    return new Promise<void>((resolve) => {
      const chars = text.split('');
      let i = 0;
      const typing = setInterval(() => {
        if (i < chars.length) {
          const char = chars[i];
          console.log(`Adding character: ${char === '\n' ? '\\n' : char}`);
          setDisplayedOutput(prev => prev + char);
          i++;
        } else {
          clearInterval(typing);
          resolve();
        }
      }, speed);
    });
  };

  const onDrop = useCallback((e: React.DragEvent<SVGSVGElement>) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    const { x, y } = getMousePositionInSVG(e);
    const newAgent = createNewAgent(data);
    setWorkflow(prevWorkflow => [...prevWorkflow, { ...newAgent, x, y, prompt: '' }]);
  }, [workflow, createNewAgent]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>, agent: Agent) => {
    if (!isConnecting && editingAgentId !== agent.id) {
      e.stopPropagation();
      setDraggedAgent(agent);
      setIsDragging(true);
      const { x, y } = getMousePositionInSVG(e);
      setDragOffset({
        x: x - agent.x,
        y: y - agent.y
      });
    }
  };

  const onMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    const { x, y } = getMousePositionInSVG(e);
    
    if (isConnecting && tempConnection) {
      setTempConnection(prev => prev ? { ...prev, x2: x, y2: y } : null);
    } else if (isDragging && draggedAgent) {
      setWorkflow(prevWorkflow =>
        prevWorkflow.map(agent =>
          agent.id === draggedAgent.id ? { ...agent, x: x - dragOffset.x, y: y - dragOffset.y } : agent
        )
      );
    }
  };

  const onMouseUp = (e: React.MouseEvent<SVGSVGElement>) => {
    if (isDragging) {
      setIsDragging(false);
      setDraggedAgent(null);
    }
  };

  const startConnecting = (agent: Agent, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const startX = agent.x + 75;
    const startY = agent.y + 25;
    const { x, y } = getMousePositionInSVG(e);
    setIsConnecting(true);
    setConnectingFrom(agent.id);
    setTempConnection({ x1: startX, y1: startY, x2: x, y2: y });
  };

  const finishConnecting = (toAgentId: string) => {
    if (isConnecting && connectingFrom && connectingFrom !== toAgentId) {
      const existingConnection = connections.find(
        conn => (conn.from === connectingFrom && conn.to === toAgentId) ||
                 (conn.from === toAgentId && conn.to === connectingFrom)
      );
  
      if (existingConnection) {
        setConnections(prev => prev.map(conn => 
          (conn === existingConnection) ? { ...conn, bidirectional: true } : conn
        ));
      } else {
        setConnections(prev => [...prev, { from: connectingFrom, to: toAgentId, bidirectional: false }]);
      }
  
      setIsConnecting(false);
      setTempConnection(null);
      setConnectingFrom(null);
    }
  };

  const handleDelete = (agentId: string) => {
    setWorkflow(prev => prev.filter(agent => agent.id !== agentId));
    setConnections(prev => prev.filter(conn => conn.from !== agentId && conn.to !== agentId));
    if (selectedAgent?.id === agentId) {
      setSelectedAgent(null);
    }
  };

  const handleAgentRename = (agentId: string, newName: string) => {
    setWorkflow(prevWorkflow =>
      prevWorkflow.map(agent =>
        agent.id === agentId ? { ...agent, name: newName } : agent
      )
    );
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedAgent) {
      const updatedPrompt = e.target.value;
      setWorkflow(prevWorkflow =>
        prevWorkflow.map(agent =>
          agent.id === selectedAgent.id ? { ...agent, prompt: updatedPrompt } : agent
        )
      );
      setSelectedAgent(prevAgent => prevAgent ? { ...prevAgent, prompt: updatedPrompt } : null);
    }
  };

  const handleRunWorkflow = () => {
    try {
      validateWorkflow(workflow, connections);
      executeWorkflow();
      ReactGA.event({
        category: 'Workflow',
        action: 'Workflow Executed',
        label: `Iterations: ${iterations}`,
      });
    } catch (error) {
      setTerminalOutput(`Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}\nPlease check your workflow configuration.`);
      setActiveTab('terminal');
    }
  };

  const filteredAgents = agentTemplates.filter((agent: Agent) => 
    agent.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  async function executeModel(agent: Agent, input: string, iteration: number): Promise<string> {
    const roleEmphasis = `Your role: ${agent.prompt}\n\nRemember to stay in character and fulfill your role in this interaction.\n\n`;
    const contextualInput = `Previous messages and current situation:\n${input}\n\n`;
    const instruction = "Respond according to your role. Limit your answer to under 400 characters.";
    
    const fullPrompt = roleEmphasis + contextualInput + instruction;
    
    const response = await callClaudeAPI(fullPrompt, agent.model);
    const outputText = `${agent.name} output (iteration ${iteration}):\n"${response}"\n\n`;
    console.log(outputText);
    await typeEffect(outputText);
    return response;
  }

  const cancelConnection = useCallback(() => {
    setIsConnecting(false);
    setTempConnection(null);
    setConnectingFrom(null);
  }, []);

  const onSvgClick = useCallback((e: React.MouseEvent<SVGSVGElement>) => {
    if (isConnecting) {
      cancelConnection();
    }
  }, [isConnecting, cancelConnection]);

  const getInverseTransformedPoint = (x: number, y: number) => {
    const { scale, positionX, positionY } = transformComponentRef.current?.state || { scale: 1, positionX: 0, positionY: 0 };
    return {
      x: (x - positionX) / scale,
      y: (y - positionY) / scale
    };
  };

  function estimateTokenCount(text: string): number {
    // A VERY VERY rough estimate: 1 token ~= 4 characters. To be discussed.
    return Math.ceil(text.length / 4);
  }
  
  function estimateWorkflowTokens(workflow: Agent[], connections: Connection[], iterations: number): number {
    let totalTokens = 0;
  
    // Function to get all predecessors of an agent
    const getPredecessors = (agentId: string): string[] => {
      return connections
        .filter(conn => conn.to === agentId)
        .map(conn => conn.from);
    };
  
    // Estimate tokens for each iteration
    for (let i = 0; i < iterations; i++) {
      workflow.forEach(agent => {
        // Tokens for the agent's prompt
        totalTokens += estimateTokenCount(agent.prompt);
  
        // Estimate tokens for inputs from predecessors
        const predecessors = getPredecessors(agent.id);
        const estimatedInputTokens = predecessors.length * 50; // Assuming 50 tokens per input
        totalTokens += estimatedInputTokens;
  
        // Estimate tokens for the agent's response
        const estimatedResponseTokens = 200; // Assume 200 tokens per response
        totalTokens += estimatedResponseTokens;
      });
    }
  
    // Add some buffer for system for other potential overhead
    const overheadTokens = 50 * workflow.length * iterations;
    totalTokens += overheadTokens;
  
    return totalTokens;
  }

  function getExecutionOrder(connections: Connection[], workflow: Agent[]): string[] {
    const graph: {[key: string]: string[]} = {};
    const inDegree: {[key: string]: number} = {};
  
    workflow.forEach(agent => {
      graph[agent.id] = [];
      inDegree[agent.id] = 0;
    });
  
    connections.forEach(conn => {
      graph[conn.from].push(conn.to);
      inDegree[conn.to]++;
      if (conn.bidirectional) {
        graph[conn.to].push(conn.from);
        inDegree[conn.from]++;
      }
    });
  
    const queue: string[] = workflow.filter(agent => inDegree[agent.id] === 0).map(agent => agent.id);
    const result: string[] = [];
  
    if (queue.length === 0 && workflow.length > 0) {
      queue.push(workflow[0].id);
    }
  
    while (queue.length > 0) {
      const current = queue.shift()!;
      result.push(current);
  
      graph[current].forEach(neighbor => {
        inDegree[neighbor]--;
        if (inDegree[neighbor] === 0) {
          queue.push(neighbor);
        }
      });
    }
  
    workflow.forEach(agent => {
      if (!result.includes(agent.id)) {
        result.push(agent.id);
      }
    });
  
    return result;
  }

  function getConnectedAgents(agentId: string, connections: Connection[]): string[] {
    return connections
      .filter(conn => conn.from === agentId)
      .map(conn => conn.to);
  }

  function validateWorkflow(workflow: Agent[], connections: Connection[]): void {
    const agentIds = new Set(workflow.map((agent: Agent) => agent.id));
    
    for (const conn of connections) {
      if (!agentIds.has(conn.from) || !agentIds.has(conn.to)) {
        throw new Error(`Invalid connection: ${conn.from} -> ${conn.to}`);
      }
    }
  }

  async function executeWorkflow() {
    setIsLoading(true);
    setActiveTab('chat');
    setDisplayedOutput('');
    setAgentMessages([]);
  
    await typeEffect("Starting workflow...\n\n");
  
    try {
      const executionOrder = getExecutionOrder(connections, workflow);
      let agentOutputs: { [key: string]: string } = {};
      let lastSpeaker: string | null = null;
  
      for (let i = 0; i < parseInt(iterations); i++) {
        await typeEffect(`Iteration ${i + 1}:\n`);
  
        for (const agentId of executionOrder) {
          const agent = workflow.find(a => a.id === agentId);
          if (agent && (i === 0 || agentId !== lastSpeaker)) {
            await typeEffect(`Executing: ${agent.name}\n\n`);
          
            const incomingConnections = connections.filter(conn => 
              conn.to === agentId || (conn.from === agentId && conn.bidirectional)
            );
            
            const inputs = incomingConnections
              .map(conn => {
                const fromId = conn.from === agentId ? conn.to : conn.from;
                return agentOutputs[fromId] || '';
              })
              .filter(Boolean);
  
            let fullPrompt = agent.prompt;
            if (inputs.length > 0) {
              fullPrompt += `\n\nPrevious message(s):\n${inputs.join('\n')}\n\n`;
            }
  
            if (agentOutputs[agentId]) {
              fullPrompt += `\n\nYour previous response:\n${agentOutputs[agentId]}\n\n`;
            }
  
            const response = await executeModel(agent, fullPrompt, i + 1);
            
            agentOutputs[agentId] = response;
  
            const newMessage = { agent: agent.name, content: response };
            setAgentMessages(prevMessages => [...prevMessages, newMessage]);
  
            lastSpeaker = agentId;
          }
        }
  
        if (i < parseInt(iterations) - 1) {
          await typeEffect("\n--- End of iteration ---\n\n");
        }
      }
    } catch (error) {
      const errorText = `\nAn error occurred: ${error instanceof Error ? error.message : 'An unknown error occurred'}\n`;
      console.error(errorText);
      await typeEffect(errorText);
    } finally {
      setIsLoading(false);
      await typeEffect("Workflow completed.\n");
    }
  }
  

  useEffect(() => {
    const terminalOutput = document.querySelector('.terminal-output');
    if (terminalOutput) {
      terminalOutput.scrollTop = terminalOutput.scrollHeight;
    }
  }, [displayedOutput]);

  return (
    <div className="App">
      <div className="content">
        <div className="explore-section">
        <div className="explore-tabs">
        <button 
            className={`explore-tab ${activeTab === 'marketplace' ? 'active' : ''}`}
            onClick={() => setActiveTab('marketplace')}
        >
            📖 Library
        </button>
        <button 
            className={`explore-tab ${activeTab === 'chat' ? 'active' : ''}`}
            onClick={() => setActiveTab('chat')}
        >
            💬 Chat
        </button>
        <button 
            className={`explore-tab ${activeTab === 'terminal' ? 'active' : ''}`}
            onClick={() => setActiveTab('terminal')}
        >
            🤖 Terminal
        </button>
        <button 
              className={`explore-tab ${activeTab === 'guide' ? 'active' : ''}`}
              onClick={() => setActiveTab('guide')}
            >
            ❓ Guide
            </button>
    </div>
    <div className="explore-content">
                        {activeTab === 'marketplace' && (
                            <>
                                <div className="explore-header">
                                    <h2>Explore</h2>
                                    <input 
                                        type="text" 
                                        placeholder="Search agents" 
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="search-input"
                                    />
                                </div>
                                <div className="agents-grid">
                                {filteredAgents.map((agent: Agent) => (
                                    <div
                                    key={agent.id}
                                    draggable
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData('text/plain', JSON.stringify(agent));
                                    }}
                                    className="agent-card"
                                    >
                                    <div 
                                        className="agent-card-image" 
                                        style={{ backgroundImage: `url(${agent.image})` }}
                                    ></div>
                                    <div className="agent-card-name">{agent.name}</div>
                                    </div>
                                ))}
                                </div>
                            </>
                        )}
                        {activeTab === 'chat' && (
                        <div className="chat-content">
                            <div className="agent-messages">
                            {agentMessages.map((message, index) => (
                                <div key={index} className="agent-message">
                                <div className="agent-name">{message.agent}</div>
                                <div className="message-content">
                                    {message.content}
                                </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        )}
                        {activeTab === 'terminal' && (
                            <div className="terminal-output">
                                <pre>{displayedOutput}</pre>
                            </div>
                        )}
                        {activeTab === 'guide' && renderGuideContent()}
                    </div>
        </div>
        <div className="right-section">
          <h2 className="workflow-title">Workflow Area</h2>
          <div className="workflow-container">
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            minScale={0.1}
            maxScale={2}
            limitToBounds={false}
            wheel={{ step: 0.1 }}
            panning={{ disabled: isConnecting }}
            ref={transformComponentRef}
            >
              <TransformComponent
  wrapperStyle={{
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }}
>
        <svg 
            ref={svgRef}
            className={`workflow ${isConnecting ? 'connecting' : ''}`}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onDrop={onDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={onSvgClick}
            width="1500"
            height="1125"
            viewBox="0 0 750 562"
          >
   <defs>
  <linearGradient id="arrow-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
    <stop offset="0%" style={{ stopColor: "#ff9a9e" }} />
    <stop offset="100%" style={{ stopColor: "#eb5757" }} />
  </linearGradient>
  <marker 
    id="arrowhead-start" 
    viewBox="0 0 10 10" 
    refX="7" 
    refY="5"
    markerWidth="6" 
    markerHeight="6" 
    orient="auto-start-reverse"
  >
    <path d="M 10 0 L 0 5 L 10 10 z" fill="url(#arrow-gradient)" />
  </marker>
  <marker 
    id="arrowhead-end" 
    viewBox="0 0 10 10" 
    refX="3" 
    refY="5"
    markerWidth="6" 
    markerHeight="6" 
    orient="auto"
  >
    <path d="M 0 0 L 10 5 L 0 10 z" fill="url(#arrow-gradient)" />
  </marker>
</defs>
{connections.map((conn, index) => {
  const fromAgent = workflow.find(a => a.id === conn.from);
  const toAgent = workflow.find(a => a.id === conn.to);
  if (fromAgent && toAgent) {
    const startPoint = {
      x: fromAgent.x + 75,
      y: fromAgent.y + 20,
    };
    const endPoint = calculateIntersection(
      fromAgent.x, fromAgent.y,
      toAgent.x, toAgent.y,
      190, 70
    );
    return (
      <g key={index}>
        <line
          x1={startPoint.x}
          y1={startPoint.y}
          x2={endPoint.x}
          y2={endPoint.y}
          stroke="url(#arrow-gradient)"
          strokeWidth="2"
          markerEnd="url(#arrowhead-end)"
          markerStart={conn.bidirectional ? "url(#arrowhead-start)" : ""}
          className="connection-line"
        />
        {conn.bidirectional && (
          <line
            x1={endPoint.x}
            y1={endPoint.y}
            x2={startPoint.x}
            y2={startPoint.y}
            stroke="url(#arrow-gradient)"
            strokeWidth="2"
            strokeDasharray="3,3"
            markerEnd="url(#arrowhead-end)"
            className="connection-line bidirectional"
          />
        )}
      </g>
    );
  }
  return null;
})}
    {tempConnection && (
      <line
        x1={tempConnection.x1}
        y1={tempConnection.y1}
        x2={tempConnection.x2}
        y2={tempConnection.y2}
        stroke="#eb5757"
        strokeWidth="2"
        strokeDasharray="5,5"
      />
    )}
    {workflow.map((agent) => (
  <foreignObject
    key={agent.id}
    x={agent.x}
    y={agent.y}
    width="220"
    height="120"
  >
    <div className="workflow-item-wrapper">
    <div
        className="workflow-item"
        onMouseDown={(e) => onMouseDown(e, agent)}
        onDoubleClick={() => setEditingAgentId(agent.id)}
        onClick={(e) => {
        e.stopPropagation();
        if (isConnecting) {
            finishConnecting(agent.id);
        }
        }}
    >
        {editingAgentId === agent.id ? (
          <input
            type="text"
            value={agent.name}
            onChange={(e) => handleAgentRename(agent.id, e.target.value)}
            onBlur={() => setEditingAgentId(null)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setEditingAgentId(null);
              }
            }}
            autoFocus
          />
        ) : (
          agent.name
        )}
      </div>
      <div className="workflow-item-buttons">
      <button onClick={(e) => {
                      e.stopPropagation();
                      if (isConnecting) {
                        cancelConnection();
                      } else {
                        startConnecting(agent, e);
                      }
                    }}>
                <Link size={16} />
              </button>
              <button onClick={(e) => { 
                e.stopPropagation(); 
                setSelectedAgent(agent);
              }}>
                <Pencil size={16} />
              </button>
              <button onClick={(e) => { e.stopPropagation(); handleDelete(agent.id); }}>
                <Trash2 size={16} />
              </button>
      </div>
    </div>
  </foreignObject>
))}
  </svg>
</TransformComponent>
            </TransformWrapper>
            <div className="workflow-controls">
              <div className="iterations-input">
                <label htmlFor="iterations">Number of iterations:</label>
                <input
                  type="text"
                  id="iterations"
                  value={iterations}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[1-9]\d*$/.test(value)) {
                      setIterations(value);
                    }
                  }}
                />
              </div>
              <div className="token-estimate">
                Estimated Credit Cost: {estimatedTokens}
            </div>
              <button className="run-workflow-button" onClick={executeWorkflow} disabled={isLoading}>
                {isLoading ? 'Running...' : 'Run Flow'}
              </button>
            </div>
          </div>

          <div className="agent-details-section"></div>

          <div className="prompt-section">
            <div className="prompt-header">
              {selectedAgent ? `Prompt for ${selectedAgent.name}` : 'Select a model to edit its prompt'}
            </div>
            <textarea 
              value={selectedAgent?.prompt || ''} 
              onChange={handlePromptChange}
              placeholder={selectedAgent ? "Define this agent's role and behavior here. Start with 'You are...' to clearly state the agent's role." : "Select an agent to edit its role and behavior"}
              className="prompt-textarea"
              disabled={!selectedAgent}
            />
          </div>

          </div>
        </div>
      </div>
  );
};

export default Workflow;