import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import { PlusCircle } from 'lucide-react';

interface Model {
  id: string;
  name: string;
  rating: number;
  imageUrl: string;
  applicationDomain: string;
  modelType: string;
  inputDataType: string;
  frameworkLibrary: string;
}

interface StackItem {
  id: string;
  name: string;
  type: 'model' | 'workflow' | 'creator';
  imageUrl: string;
  domain: string;
}

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(1000);
  const [filters, setFilters] = useState({
    applicationDomain: '',
    modelType: '',
    inputDataType: '',
    frameworkLibrary: '',
    rating: 0
  });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [exploreSearchQuery, setExploreSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState<'models' | 'workflows' | 'creators' | null>(null);

  const modelData: Model[] = [
    { id: '1', name: 'Flow 1', rating: 4.5, imageUrl: 'https://placehold.co/200x200/2196f3/ffffff?text=Flow1', applicationDomain: 'NLP', modelType: 'Transformer', inputDataType: 'Text', frameworkLibrary: 'PyTorch' },
    { id: '2', name: 'Flow 2', rating: 3.8, imageUrl: 'https://placehold.co/200x200/4caf50/ffffff?text=Flow2', applicationDomain: 'Computer Vision', modelType: 'CNN', inputDataType: 'Image', frameworkLibrary: 'TensorFlow' },
    { id: '3', name: 'Flow 3', rating: 4.2, imageUrl: 'https://placehold.co/200x200/ff9800/ffffff?text=Flow3', applicationDomain: 'Speech Recognition', modelType: 'RNN', inputDataType: 'Audio', frameworkLibrary: 'Keras' },
    { id: '4', name: 'Flow 4', rating: 4.7, imageUrl: 'https://placehold.co/200x200/9c27b0/ffffff?text=Flow4', applicationDomain: 'NLP', modelType: 'BERT', inputDataType: 'Text', frameworkLibrary: 'Hugging Face' },
    { id: '5', name: 'Flow 5', rating: 3.9, imageUrl: 'https://placehold.co/200x200/e91e63/ffffff?text=Flow5', applicationDomain: 'Recommender Systems', modelType: 'Collaborative Filtering', inputDataType: 'User-Item Interactions', frameworkLibrary: 'Surprise' },
    { id: '6', name: 'Flow 6', rating: 4.1, imageUrl: 'https://placehold.co/200x200/00bcd4/ffffff?text=Flow6', applicationDomain: 'Time Series', modelType: 'LSTM', inputDataType: 'Numerical', frameworkLibrary: 'Scikit-learn' },
    { id: '7', name: 'Flow 7', rating: 4.3, imageUrl: 'https://placehold.co/200x200/ffc107/ffffff?text=Flow7', applicationDomain: 'Computer Vision', modelType: 'YOLO', inputDataType: 'Image', frameworkLibrary: 'Darknet' },
    { id: '8', name: 'Flow 8', rating: 4.0, imageUrl: 'https://placehold.co/200x200/795548/ffffff?text=Flow8', applicationDomain: 'NLP', modelType: 'GPT', inputDataType: 'Text', frameworkLibrary: 'OpenAI' },
  ];

  const stackItems: StackItem[] = [
    { id: '1', name: 'Flow 1', type: 'workflow', imageUrl: 'https://placehold.co/200x200/2196f3/ffffff?text=F1', domain: 'NLP' },
    { id: '2', name: 'Model A', type: 'model', imageUrl: 'https://placehold.co/200x200/4caf50/ffffff?text=MA', domain: 'Computer Vision' },
    { id: '3', name: 'Creator X', type: 'creator', imageUrl: 'https://placehold.co/200x200/ff9800/ffffff?text=CX', domain: 'Speech Recognition' },
    { id: '4', name: 'Flow 2', type: 'workflow', imageUrl: 'https://placehold.co/200x200/9c27b0/ffffff?text=F2', domain: 'NLP' },
    { id: '5', name: 'Model B', type: 'model', imageUrl: 'https://placehold.co/200x200/e91e63/ffffff?text=MB', domain: 'Recommender Systems' },
    { id: '6', name: 'Creator Y', type: 'creator', imageUrl: 'https://placehold.co/200x200/00bcd4/ffffff?text=CY', domain: 'Time Series' },
    { id: '7', name: 'Flow 3', type: 'workflow', imageUrl: 'https://placehold.co/200x200/ffc107/ffffff?text=F3', domain: 'Computer Vision' },
    { id: '8', name: 'Model C', type: 'model', imageUrl: 'https://placehold.co/200x200/795548/ffffff?text=MC', domain: 'NLP' },
  ];

  const filteredStackItems = activeFilter
    ? stackItems.filter(item => item.type === activeFilter.slice(0, -1))
    : stackItems;

  const handleFilterChange = (filterName: keyof typeof filters, value: string | number) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const handleFlowClick = (flowId: string) => {
    navigate(`/flow/${flowId}`);
  };

  const filteredModels = modelData.filter(model => 
    (exploreSearchQuery === '' || model.name.toLowerCase().includes(exploreSearchQuery.toLowerCase())) &&
    (!filters.applicationDomain || model.applicationDomain === filters.applicationDomain) &&
    (!filters.modelType || model.modelType === filters.modelType) &&
    (!filters.inputDataType || model.inputDataType === filters.inputDataType) &&
    (!filters.frameworkLibrary || model.frameworkLibrary === filters.frameworkLibrary) &&
    (!filters.rating || model.rating >= filters.rating)
  );

  const handleCreateWorkflow = () => {
    setIsTransitioning(true);
    navigate('/workflow');
  };

  return (
    <div className="homepage">
      <aside className="sidebar">
        <div className="logo">
          <div className="logo-icon">
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1394.55 486.5">
              <g>
                  <g>
                  <rect fill="#DA615C" x="672.34" y="191.84" width="396.31" height="101.67" transform="translate(83.37 686.61) rotate(-45)"/>
                  <polygon fill="#DA615C" points="819.7 421.77 973 486.5 879.81 321.58 975.29 292.18 979.98 109.46 719.69 321.58 786.56 364.83 819.7 421.77"/>
                  <polygon fill="#DA615C" points="256.12 486.5 374.63 396.64 413.79 266.1 488.85 295.48 488.85 109.46 133.13 406.43 73.82 486.5 256.12 486.5"/>
                  <polygon fill="#DA615C" points="1394.55 486.5 974.66 66.61 902.77 138.5 1250.76 486.5 1394.55 486.5"/>
                  <polygon fill="#DA615C" points="143.79 486.5 486.5 143.79 829.21 486.5 973 486.5 558.39 71.89 486.5 0 414.61 71.89 0 486.5 143.79 486.5"/>
                  </g>
              </g>
              </svg>
          </div>
          <h1>Zarathustra</h1>
          </div>
        <div className="categories">
          <button 
            className={activeFilter === 'models' ? 'active' : ''}
            onClick={() => setActiveFilter(activeFilter === 'models' ? null : 'models')}
          >
            Models
          </button>
          <button 
            className={activeFilter === 'workflows' ? 'active' : ''}
            onClick={() => setActiveFilter(activeFilter === 'workflows' ? null : 'workflows')}
          >
            Flows
          </button>
          <button 
            className={activeFilter === 'creators' ? 'active' : ''}
            onClick={() => setActiveFilter(activeFilter === 'creators' ? null : 'creators')}
          >
            Creators
          </button>
        </div>
        <h2 className="stack-title">Your Stack</h2>
        <ul className="stack-list">
          {filteredStackItems.map((item) => (
            <li key={item.id}>
              <div className="item-image" style={{backgroundImage: `url(${item.imageUrl})`}}></div>
              <div className="item-info">
                <h3>{item.name}</h3>
                <p>{item.type}</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="create-buttons">
          <button onClick={handleCreateWorkflow}>Create new workflow</button>
        </div>
      </aside>
      <main>
        <header>
          <input type="text" placeholder="Hey Thomas, what are you interested in?" />
          <div className="user-actions">
          <div className="new-workflow-tooltip">
              <button className="new-workflow-button" onClick={() => navigate('/workflow')}>
                <PlusCircle size={24} />
              </button>
              <span className="tooltip-text">Build new flow</span>
            </div>
            <div className="credits-counter">{credits} credits</div>
            <div className="profile-dropdown">
              <div 
                className="profile-picture" 
                style={{backgroundImage: `url('https://startupkitchen.community/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-22-at-2.51.00-PM.jpeg')`}}
              ></div>
              <div className="dropdown-menu">
                <button onClick={() => navigate('/settings')}>Settings</button>
                <button onClick={() => navigate('/creator-dashboard')}>Creator's Dashboard</button>
                <button onClick={() => navigate('/profile')}>Profile</button>
                <button onClick={() => { /* handle logout */ }}>Logout</button>
              </div>
            </div>
          </div>
        </header>
        
        <section className="recently-used">
          <h2>Recently Used</h2>
          <div className="grid">
            {modelData.slice(0, 4).map((model) => (
              <div key={model.id} className="grid-item" onClick={() => handleFlowClick(model.id)}>
                <div className="model-image" style={{backgroundImage: `url(${model.imageUrl})`}}></div>
                <div className="model-info">
                  <p className="model-name">{model.name}</p>
                  <div className="rating-bar">
                    <div className="rating-fill" style={{width: `${(model.rating / 5) * 100}%`}}></div>
                  </div>
                  <p className="model-rating">{model.rating.toFixed(1)}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="explore">
          <h2>Explore</h2>
          <input
            type="text"
            placeholder="Search models..."
            value={exploreSearchQuery}
            onChange={(e) => setExploreSearchQuery(e.target.value)}
            className="explore-search"
          />
          <div className="filters">
            <select onChange={(e) => handleFilterChange('applicationDomain', e.target.value)}>
              <option value="">All Application Domains</option>
              <option value="NLP">NLP</option>
              <option value="Computer Vision">Computer Vision</option>
              <option value="Speech Recognition">Speech Recognition</option>
              <option value="Recommender Systems">Recommender Systems</option>
              <option value="Time Series">Time Series</option>
            </select>
            <select onChange={(e) => handleFilterChange('modelType', e.target.value)}>
              <option value="">All Model Types</option>
              <option value="Transformer">Transformer</option>
              <option value="CNN">CNN</option>
              <option value="RNN">RNN</option>
              <option value="BERT">BERT</option>
              <option value="LSTM">LSTM</option>
              <option value="YOLO">YOLO</option>
              <option value="GPT">GPT</option>
            </select>
            <select onChange={(e) => handleFilterChange('inputDataType', e.target.value)}>
              <option value="">All Input Data Types</option>
              <option value="Text">Text</option>
              <option value="Image">Image</option>
              <option value="Audio">Audio</option>
              <option value="Numerical">Numerical</option>
              <option value="User-Item Interactions">User-Item Interactions</option>
            </select>
            <select onChange={(e) => handleFilterChange('frameworkLibrary', e.target.value)}>
              <option value="">All Frameworks/Libraries</option>
              <option value="PyTorch">PyTorch</option>
              <option value="TensorFlow">TensorFlow</option>
              <option value="Keras">Keras</option>
              <option value="Hugging Face">Hugging Face</option>
              <option value="Scikit-learn">Scikit-learn</option>
              <option value="OpenAI">OpenAI</option>
            </select>
            <select onChange={(e) => handleFilterChange('rating', Number(e.target.value))}>
              <option value="0">All Ratings</option>
              <option value="4">4+ Stars</option>
              <option value="3">3+ Stars</option>
              <option value="2">2+ Stars</option>
              <option value="1">1+ Stars</option>
            </select>
          </div>
          <div className="grid">
            {filteredModels.map((model) => (
              <div key={model.id} className="grid-item" onClick={() => handleFlowClick(model.id)}>
                <div className="model-image" style={{backgroundImage: `url(${model.imageUrl})`}}></div>
                <div className="model-info">
                  <p className="model-name">{model.name}</p>
                  <div className="rating-bar">
                    <div className="rating-fill" style={{width: `${(model.rating / 5) * 100}%`}}></div>
                  </div>
                  <p className="model-rating">{model.rating.toFixed(1)}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;