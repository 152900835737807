import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initGA, logPageView, logEvent } from './ga';
import './LoginPage.css';

interface VerificationFormProps {
    verificationCode: string;
    onVerificationCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent) => void;
  }
  
  const VerificationForm: React.FC<VerificationFormProps> = React.memo(({ verificationCode, onVerificationCodeChange, onSubmit }) => (
    <div className="verification-form-container">
      <h2>Verify Your Account</h2>
      <p>Please enter the 6-digit code sent to your email.</p>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="verification-code">Verification Code</label>
          <input
            type="text"
            id="verification-code"
            value={verificationCode}
            onChange={onVerificationCodeChange}
            maxLength={6}
            inputMode="numeric"
            autoComplete="one-time-code"
            required
          />
        </div>
        <button type="submit" className="verify-button">Verify</button>
      </form>
    </div>
  ));

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeSection, setActiveSection] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showSignUpPassword, setShowSignUpPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [currentForm, setCurrentForm] = useState<'login' | 'signup' | 'verification'>('login');
  const location = useLocation();
  
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const newActiveSection = Math.floor(scrollPosition / (windowHeight * 0.7));
      setActiveSection(newActiveSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const starsContainer = document.querySelector('.stars');
    for (let i = 0; i < 50; i++) {
      const star = document.createElement('div');
      star.classList.add('star');
      star.style.width = `${Math.random() * 2}px`;
      star.style.height = star.style.width;
      star.style.left = `${Math.random() * 100}%`;
      star.style.top = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starsContainer?.appendChild(star);
    }
  }, []);

  const togglePasswordVisibility = (formType: 'login' | 'signup') => {
    if (formType === 'login') {
      setShowLoginPassword(!showLoginPassword);
    } else {
      setShowSignUpPassword(!showSignUpPassword);
    }
  };

  useEffect(() => {
    const GA_MEASUREMENT_ID = 'G-JXQ1XWMPRZ';
    initGA(GA_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  const checkPasswordStrength = (password: string) => {
    const requirements = {
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*<>-]/.test(password),
    };
  
    setPasswordRequirements(requirements);
  
    const strength = Object.values(requirements).filter(Boolean).length;
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  const isPasswordValid = passwordStrength >= 3 && passwordRequirements.length;

  const handleAuth = async (e: React.FormEvent, action: 'login' | 'register' | 'verify') => {
    e.preventDefault();
    logEvent({
        category: 'Form',
        action: `${action.charAt(0).toUpperCase() + action.slice(1)} Submission`,
        label: 'Email',
      });
    setErrors({});
  
    if (action === 'register') {
      if (password !== confirmPassword) {
        setErrors({ confirmPassword: 'Passwords do not match' });
        return;
      }
      if (!isPasswordValid) {
        setErrors({ password: 'Password does not meet the requirements' });
        return;
      }
    }
  
    try {
      const payload = {
        action,
        email,
        ...(action !== 'verify' && { password }),
        ...(action === 'verify' && { verificationCode }),
      };
  
      console.log(`Sending ${action} request:`, payload);
  
      const response = await fetch('https://x4qj1yq3r9.execute-api.eu-north-1.amazonaws.com/prod/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      console.log(`${action} response:`, data);
  
      if (response.ok) {
        logEvent({
            category: 'User',
            action: `${action.charAt(0).toUpperCase() + action.slice(1)} Success`,
            label: 'Email',
          });
        switch (action) {
          case 'login':
            localStorage.setItem('authToken', data.token);
            setIsTransitioning(true);
            setTimeout(() => navigate('/home'), 1000);
            break;
          case 'register':
            setCurrentForm('verification');
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 5000);
            break;
          case 'verify':
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
              setCurrentForm('login');
            }, 5000);
            break;
        }
      } else {
        logEvent({
            category: 'User',
            action: `${action.charAt(0).toUpperCase() + action.slice(1)} Failure`,
            label: 'Email',
          });
        throw new Error(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error(`Error during ${action}:`, error);
      setErrors({ 
        [action]: error instanceof Error ? error.message : 'An unexpected error occurred'
      });
  
      if (action !== 'verify') {
        const emailInput = document.getElementById(action === 'login' ? 'email' : 'signup-email');
        const passwordInput = document.getElementById(action === 'login' ? 'password' : 'signup-password');
        
        emailInput?.classList.add('error');
        passwordInput?.classList.add('error');
        
        setTimeout(() => {
          emailInput?.classList.remove('error');
          passwordInput?.classList.remove('error');
        }, 820);
      }
    }
  };

  const handleVerificationCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  }, []);

  const handleVerificationSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    handleAuth(e, 'verify');
  }, [handleAuth]);

  const handleDocsClick = () => {
    window.open('https://docs.zarathustra.network', '_blank');

    logEvent({
        category: 'Navigation',
        action: 'Docs Click',
        label: 'External Link',
      });
  };

  const handleGoogleLogin = () => {
    console.log('Google login attempted');
    // Implement Google login logic here
  };

  const toggleSignUp = () => {
    setCurrentForm(currentForm === 'login' ? 'signup' : 'login');
    setErrors({});
    setEmail('');
    setPassword('');
    setConfirmPassword('');

    logEvent({
    category: 'User',
    action: 'Toggle Signup Form',
    label: currentForm === 'login' ? 'To Signup' : 'To Login',
    });
  };

  return (
    <div className={`login-page ${isTransitioning ? 'transitioning' : ''}`}>
      <div className="login-left">
        <div className="logo">
          <div className="logo-icon-login">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1394.55 486.5">
              <g id="_Слой_1" data-name="Слой 1">
                <g>
                  <rect fill="#DA615C" x="672.34" y="191.84" width="396.31" height="101.67" transform="translate(83.37 686.61) rotate(-45)"/>
                  <polygon fill="#DA615C" points="819.7 421.77 973 486.5 879.81 321.58 975.29 292.18 979.98 109.46 719.69 321.58 786.56 364.83 819.7 421.77"/>
                  <polygon fill="#DA615C" points="256.12 486.5 374.63 396.64 413.79 266.1 488.85 295.48 488.85 109.46 133.13 406.43 73.82 486.5 256.12 486.5"/>
                  <polygon fill="#DA615C" points="1394.55 486.5 974.66 66.61 902.77 138.5 1250.76 486.5 1394.55 486.5"/>
                  <polygon fill="#DA615C" points="143.79 486.5 486.5 143.79 829.21 486.5 973 486.5 558.39 71.89 486.5 0 414.61 71.89 0 486.5 143.79 486.5"/>
                </g>
              </g>
            </svg>
          </div>
          <h1>Zarathustra</h1>
        </div>
        <div className="deck-container">
          <section className={`deck-section ${activeSection === 0 ? 'active' : ''}`}>
            <h2>Welcome 👋</h2>
            <p>Discover an open platform where intelligent agents are effortlessly integrated into any workflow you can imagine.</p>
            <p>Think of it as the 'Spotify' for intelligent agents: but instead of a platform of songs, it's made up of smart friends. Customize and assemble workflows of agents just like creating your favorite playlists out of songs.</p>
          </section>
          <div className="button-container">
            <button className="docs-button" onClick={handleDocsClick}>Check out the Docs</button>
          </div>
        </div>
        <p className="copyright">© 2024 Zarathustra. All rights reserved.</p>
      </div>
      <div className="login-right">
      <div className={`form-container ${currentForm !== 'login' ? `show-${currentForm}` : ''}`}>
      <div className="login-form-container" style={{opacity: currentForm === 'login' ? 1 : 0, transform: currentForm === 'login' ? 'translateX(0)' : 'translateX(-100%)'}}>
            <h2>Let's Get Building</h2>
            <p>Please enter your details to sign in.</p>
            <form onSubmit={(e) => handleAuth(e, 'login')}>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={errors.email ? 'error' : ''}
                  required
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>
              <div className="form-group">
              <div className="form-group password-input-wrapper">
                <label htmlFor="password">Password</label>
                <div className="password-input-container">
                    <input
                    type={showLoginPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={errors.password ? 'error' : ''}
                    required
                    />
                    <button
                        type="button"
                        className="password-toggle login-password-toggle"
                        onClick={() => togglePasswordVisibility('login')}
                        >
                        {showLoginPassword ? 'Hide' : 'Show'}
                        </button>
                </div>
                {errors.password && <span className="error-message">{errors.password}</span>}
                </div>
              </div>
              <button type="submit" className="login-button">Sign In</button>
            </form>
            <div className="divider">
              <span>or</span>
            </div>
            <button onClick={handleGoogleLogin} className="google-login-button">
              <svg className="google-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24">
                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
              </svg>
              Sign in with Google
            </button>
            <p className="signup-prompt">
              Don't have an account? <button onClick={toggleSignUp}>Sign Up</button>
            </p>
            <div className="signup-incentive">
              <span className="incentive-icon">🎁</span>
              <p>Get 1000 free credits when you sign up!</p>
            </div>
          </div>
          <div className="signup-form-container" style={{opacity: currentForm === 'signup' ? 1 : 0, transform: currentForm === 'signup' ? 'translateX(0)' : 'translateX(100%)'}}>
            <h2>Create an Account</h2>
            <p>Please fill in the details to sign up.</p>
            <form onSubmit={(e) => handleAuth(e, 'register')}>
              <div className="form-group">
                <label htmlFor="signup-email">Email address</label>
                <input
                  type="email"
                  id="signup-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group password-input-wrapper">
                <label htmlFor="signup-password">Password</label>
                <div className="password-input-container">
                    <input
                    type={showSignUpPassword ? "text" : "password"}
                    id="signup-password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    />
                    <button
                    type="button"
                    className="password-toggle signup-password-toggle"
                    onClick={() => togglePasswordVisibility('signup')}
                    >
                    {showSignUpPassword ? 'Hide' : 'Show'}
                    </button>
                </div>
                <div className="password-strength">
                    <div 
                    className="strength-bar" 
                    style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                </div>
                <ul className="password-requirements">
                                    <li className={passwordRequirements.length ? 'met' : ''}>Minimum length of 6 characters</li>
                                    <li className={passwordRequirements.uppercase ? 'met' : ''}>Contains uppercase letter</li>
                                    <li className={passwordRequirements.lowercase ? 'met' : ''}>Contains lowercase letter</li>
                                    <li className={passwordRequirements.number ? 'met' : ''}>Contains number</li>
                                    <li className={passwordRequirements.symbol ? 'met' : ''}>Contains symbol (!@#$%^&*{'<'}&gt;-)</li>
                                    </ul>
                </div>

              <div className="form-group">
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={errors.confirmPassword ? 'error' : ''}
                  required
                />
                {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
              </div>
              <button type="submit" className="signup-button" disabled={!isPasswordValid}>Sign Up</button>
            </form>
            <p className="login-prompt">
              Already have an account? <button onClick={toggleSignUp}>Sign in</button>
            </p>
          </div>
          <div className="verification-form-container" style={{opacity: currentForm === 'verification' ? 1 : 0, transform: currentForm === 'verification' ? 'translateX(0)' : 'translateX(100%)'}}>
          <VerificationForm
            verificationCode={verificationCode}
            onVerificationCodeChange={handleVerificationCodeChange}
            onSubmit={handleVerificationSubmit}
        />
            {errors.verify && <span className="error-message">{errors.verify}</span>}
            </div>
        </div>
      </div>
      {showNotification && (
        <div className="notification">
            {currentForm === 'verification' 
            ? "Registration successful. Please check your email for the verification code." 
            : currentForm === 'login'
                ? "Account verified successfully. You can now log in."
                : "Account created successfully! Please log in."}
        </div>
        )}
    </div>
  );
};

export default LoginPage;