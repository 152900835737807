import Anthropic from '@anthropic-ai/sdk';

const CLAUDE_API_KEY = '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';

if (!CLAUDE_API_KEY) {
  console.error('Claude API key is not set. Please check your environment variables.');
}

const anthropic = new Anthropic({
  apiKey: CLAUDE_API_KEY,
  dangerouslyAllowBrowser: true
});

export type ClaudeModel = 'claude-3-opus-20240229' | 'claude-3-5-sonnet-20240620' | 'claude-3-haiku-20240307';

export async function callClaudeAPI(prompt: string, model: ClaudeModel): Promise<string> {
  if (!CLAUDE_API_KEY) {
    throw new Error('Claude API key is not set. Please check your environment variables.');
  }

  try {
    const response = await anthropic.messages.create({
      model: model,
      max_tokens: 1024,
      messages: [{ role: 'user', content: prompt }]
    });

    console.log('API Response:', response);

    const content = response.content.reduce((acc, block) => {
      if ('type' in block && block.type === 'text') {
        return acc + block.text;
      }
      return acc;
    }, '');

    return content;

  } catch (error) {
    console.error('API call failed:', error instanceof Error ? error.message : 'An unknown error occurred');
    throw new Error(`API call failed: ${error instanceof Error ? error.message : 'An unknown error occurred'}`);
  }
}